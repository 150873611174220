// WeatherUtils.js
// Definišemo funkcije za generisanje alert kodova i tekstova

export const getAlert = (weather) => {
  if (weather.icon.trim().substring(1) === "11" && weather.pcp > 0.8) return "a04b";
  if (weather.icon.trim().substring(1) === "13" && weather.pcp > 0.6) return "a05";
  if ((weather.icon.trim().substring(1) === "14" || weather.icon.trim() === "15") && weather.tmp < -1) return "a05";
  if (weather.pcp > 1.5 && weather.tmp > 1.1) return "a03";
  if (weather.ws > 10) return "a02";
  if (weather.tmp > 32 && weather.rh > 39) return "a06";
  if (weather.tmp > 37 && weather.uv > 6.5) return "a06";
  if (weather.tmp > 36 && weather.uv > 7.0) return "a01";
  if (weather.tmp > 33 && weather.uv > 9) return "a01";
  if (weather.uv > 9.0) return "a01";
  if (weather.tmp < -1 && weather.pcp > 0.5) return "a04";
  if (weather.ws > 7 && weather.pcp > 1.2) return "a05";
  return null;
};

export const AlertMessages = {
  heavySnowfall: {
    en: "Heavy snowfall!",
    el: "Ισχυρή χιονόπτωση!",
    hr: "Obilne snježne padavine!",
    rs: "Obilne snežne padavine!",
    al: "Breshër i rëndë!",
    mk: "Густ снегопад!",
    de: "Starker Schneefall!",
    it: "Forti nevicate!",
    es: "Fuerte nevada!"
  },
  stormyWindWithRain: {
    en: "Stormy wind with rain!",
    el: "Θυελλώδης άνεμος με βροχή!",
    hr: "Olujni vjetar s kišom!",
    rs: "Olujni vetar sa kišom!",
    al: "Erë e fortë me shi!",
    mk: "Бура со дожд!",
    de: "Stürmischer Wind mit Regen!",
    it: "Vento tempestoso con pioggia!",
    es: "Viento tormentoso con lluvia!"
  },
  fogIcyDay: {
    en: "Fog, icy day!",
    el: "Ομίχλη, παγωμένη μέρα!",
    hr: "Magla, ledeni dan!",
    rs: "Magla, ledeni dan!",
    al: "Mjegull, ditë e ngrirë!",
    mk: "Магла, замрзнат ден!",
    de: "Nebel, eisiger Tag!",
    it: "Nebbia, giornata gelida!",
    es: "Niebla, día helado!"
  },
  heavyRain: {
    en: "Heavy rain!",
    el: "Ισχυρή βροχή!",
    hr: "Obilna kiša!",
    rs: "Obilna kiša!",
    al: "Shi i rëndë!",
    mk: "Голем дожд!",
    de: "Starker Regen!",
    it: "Pioggia forte!",
    es: "Lluvia intensa!"
  },
  strongWind: {
    en: "Strong wind!",
    el: "Ισχυρός άνεμος!",
    hr: "Jak vjetar!",
    rs: "Jak vetar!",
    al: "Erë e fortë!",
    mk: "Силен ветер!",
    de: "Starker Wind!",
    it: "Vento forte!",
    es: "Viento fuerte!"
  },
  extremelyWarm: {
    en: "Extremely warm, increased humidity in the air!",
    el: "Ακραία ζέστη, αυξημένη υγρασία στον αέρα!",
    hr: "Izuzetno toplo, povećana vlaga u zraku!",
    rs: "Izuzetno toplo, povećana vlaga u vazduhu!",
    al: "Shumë ngrohtë, lagështi e rritur në ajër!",
    mk: "Екстремно топло, зголемена влажност во воздухот!",
    de: "Extrem warm, erhöhte Luftfeuchtigkeit!",
    it: "Estremamente caldo, umidità aumentata nell'aria!",
    es: "Extremadamente cálido, humedad aumentada en el aire!"
  },
  veryHotHighUV: {
    en: "Very hot, high UV index!",
    el: "Πολύ ζεστό, υψηλός δείκτης UV!",
    hr: "Vrlo vruće, visok UV indeks!",
    rs: "Vrlo toplo, visok UV indeks!",
    al: "Shumë nxehtë, indeks i lartë i UV!",
    mk: "Многу топло, висок УВ индекс!",
    de: "Sehr heiß, hoher UV-Index!",
    it: "Molto caldo, indice UV elevato!",
    es: "Muy caluroso, alto índice UV!"
  },
  icySlipperyRoad: {
    en: "Icy and slippery road, cold day with precipitation!",
    el: "Παγωμένοι και ολισθηροί δρόμοι, κρύα μέρα με κατακρημνίσματα!",
    hr: "Zaleđeni i klizavi putevi, hladan dan s padavinama!",
    rs: "Zaleđeni i klizavi putevi, hladan dan sa padavinama!",
    al: "Rrugë të ngrira dhe të rrëshqitshme, ditë e ftohtë me reshje!",
    mk: "Ледени и лизгави патишта, студен ден со врнежи!",
    de: "Vereiste und rutschige Straßen, kalter Tag mit Niederschlag!",
    it: "Strade ghiacciate e scivolose, giornata fredda con precipitazioni!",
    es: "Carretera helada y resbaladiza, día frío con precipitaciones!"
  },
  stormyWindWithPrecipitation: {
    en: "Stormy wind with precipitation!",
    el: "Θυελλώδης άνεμος με κατακρημνίσματα!",
    hr: "Olujni vjetar s padavinama!",
    rs: "Olujni vetar sa padavinama!",
    al: "Erë e fortë me reshje!",
    mk: "Бура со врнежи!",
    de: "Stürmischer Wind mit Niederschlag!",
    it: "Vento tempestoso con precipitazioni!",
    es: "Viento tormentoso con precipitaciones!"
  }
};

export const getAlertText = (weather, userLang = 'en') => {
  // Map conditions to message keys
  let messageKey = null;

  if (weather.icon.trim().substring(1) === "11" && weather.pcp > 0.8) {
    messageKey = 'heavySnowfall';
  } else if (weather.icon.trim().substring(1) === "13" && weather.pcp > 0.6) {
    messageKey = 'stormyWindWithRain';
  } else if (
    (weather.icon.trim().substring(1) === "14" || weather.icon.trim() === "15") &&
    weather.tmp < -1
  ) {
    messageKey = 'fogIcyDay';
  } else if (weather.pcp > 1.5 && weather.tmp > 1) {
    messageKey = 'heavyRain';
  } else if (weather.ws > 10) {
    messageKey = 'strongWind';
  } else if (weather.tmp > 32 && weather.rh > 39) {
    messageKey = 'extremelyWarm';
  } else if (
    (weather.tmp > 37 && weather.uv > 6.5) ||
    (weather.tmp > 36 && weather.uv > 7.0) ||
    (weather.tmp > 33 && weather.uv > 9) ||
    (weather.uv > 9.0)
  ) {
    messageKey = 'veryHotHighUV';
  } else if (weather.tmp < -1 && weather.pcp > 0.5) {
    messageKey = 'icySlipperyRoad';
  } else if (weather.ws > 7 && weather.pcp > 1.2) {
    messageKey = 'stormyWindWithPrecipitation';
  }

  if (messageKey) {
    const messageObj = AlertMessages[messageKey];
    if (messageObj) {
      // Default to English if the specified language is not available
      return messageObj[userLang] || messageObj['en'];
    } else {
      return null;
    }
  } else {
    return null;
  }
};


// Temperature ranges for Light Mode
const temperatureRangesLight = [
  { range: [-Infinity, -42], color: "B224B2" },
  { range: [-41, -40], color: "A724B2" },
  { range: [-39, -35], color: "9B24B2" },
  { range: [-34, -30], color: "8F24B2" },
  { range: [-29, -28], color: "7E24B2" },
  { range: [-27, -26], color: "6D24B2" },
  { range: [-25, -24], color: "5D24B2" },
  { range: [-23, -22], color: "4C24B2" },
  { range: [-21, -20], color: "432DB2" },
  { range: [-19, -18], color: "332DB2" },
  { range: [-17, -16], color: "2D36B2" },
  { range: [-15, -14], color: "2D45B2" },
  { range: [-13, -12], color: "305BBF" },
  { range: [-11, -10], color: "2666BF" },
  { range: [-9, -8], color: "2678BF" },
  { range: [-7, -6], color: "268ABF" },
  { range: [-5, -4], color: "269CBF" },
  { range: [-3, -2], color: "24A2B2" },
  { range: [-1, 0], color: "21A6A6" },
  { range: [1, 2], color: "21A68B" },
  { range: [3, 4], color: "21A671" },
  { range: [5, 6], color: "21A656" },
  { range: [7, 8], color: "21A63C" },
  { range: [9, 10], color: "21A621" },
  { range: [11, 12], color: "3CA621" },
  { range: [13, 14], color: "4CA611" },
  { range: [15, 16], color: "6AA611" },
  { range: [17, 18], color: "89AB00" },
  { range: [19, 20], color: "B8B800" },
  { range: [21, 22], color: "CCA300" },
  { range: [23, 24], color: "CC7E0A" },
  { range: [25, 26], color: "CC5E14" },
  { range: [27, 28], color: "CC3914" },
  { range: [29, 30], color: "BF1D1D" },
  { range: [31, 32], color: "C4142B" },
  { range: [33, 34], color: "C71444" },
  { range: [35, 36], color: "C9145D" },
  { range: [37, 38], color: "CC1476" },
  { range: [39, 40], color: "CC148F" },
  { range: [41, 45], color: "CC149E" },
  { range: [46, Infinity], color: "CC14AD" }
];

// Temperature ranges for Dark Mode
const temperatureRangesDark = [
  { range: [-Infinity, -42], color: "FF33FF" },
  { range: [-41, -40], color: "EE33FF" },
  { range: [-39, -35], color: "DD33FF" },
  { range: [-34, -30], color: "CC33FF" },
  { range: [-29, -28], color: "B433FF" },
  { range: [-27, -26], color: "9C33FF" },
  { range: [-25, -24], color: "8C40FF" },
  { range: [-23, -22], color: "7F4DFF" },
  { range: [-21, -20], color: "6A4DFF" },
  { range: [-19, -18], color: "554DFF" },
  { range: [-17, -16], color: "4D58FF" },
  { range: [-15, -14], color: "4D6DFF" },
  { range: [-13, -12], color: "4079FF" },
  { range: [-11, -10], color: "3388FF" },
  { range: [-9, -8], color: "33A0FF" },
  { range: [-7, -6], color: "33B8FF" },
  { range: [-5, -4], color: "33CFFF" },
  { range: [-3, -2], color: "33E7FF" },
  { range: [-1, 0], color: "33FFFF" },
  { range: [1, 2], color: "33FFD6" },
  { range: [3, 4], color: "33FFAD" },
  { range: [5, 6], color: "33FF85" },
  { range: [7, 8], color: "33FF5C" },
  { range: [9, 10], color: "33FF33" },
  { range: [11, 12], color: "5CFF33" },
  { range: [13, 14], color: "85FF33" },
  { range: [15, 16], color: "A3FF19" },
  { range: [17, 18], color: "CCFF00" },
  { range: [19, 20], color: "FFFF00" },
  { range: [21, 22], color: "FFCC00" },
  { range: [23, 24], color: "FF9E0D" },
  { range: [25, 26], color: "FF7519" },
  { range: [27, 28], color: "FF4719" },
  { range: [29, 30], color: "FF2626" },
  { range: [31, 32], color: "FF1A38" },
  { range: [33, 34], color: "FF1A57" },
  { range: [35, 36], color: "FF1A75" },
  { range: [37, 38], color: "FF1994" },
  { range: [39, 40], color: "FF19B2" },
  { range: [41, 45], color: "FF19C6" },
  { range: [46, Infinity], color: "FF19D9" }
];

// Function to get the temperature color based on the current theme
export const getTemperatureColor = (temp, isDarkMode) => {
  const temperatureRanges = isDarkMode ? temperatureRangesDark : temperatureRangesLight;
  
  for (const { range, color } of temperatureRanges) {
    if (temp >= range[0] && temp <= range[1]) {
      return `#${color}`; // Return color as hex
    }
  }
  
  return '#000000'; // Default color if temp doesn't fit any range
};

// Function to get UV color
export const getUVColor = (uv) => {
  if (uv >= 0.0 && uv <= 1.9) return "#289500";
  if (uv >= 2.0 && uv <= 4.9) return "#ff9600";
  if (uv >= 5.0 && uv <= 7.9) return "#f85900";
  return "#d80010";
};

// Konverzija brzine vetra na osnovu zadate jedinice
export const convertWindSpeed = (speed, unit) => {
  const formattedSpeed = (speed) => {
    if (speed === 0) {
      return "-";
    }
    return speed % 1 === 0 ? speed.toFixed(0) : speed.toFixed(1);
  };

  switch (unit) {
    case 'ms':
      return `${formattedSpeed(speed)} m/s`;
    case 'kmh':
      return `${formattedSpeed(speed * 3.6)} km/h`;
    case 'knots':
      return `${formattedSpeed(speed * 1.944)} knots`;
    case 'mph':
      return `${formattedSpeed(speed * 2.23694)} mph`;
    case 'fts':
      return `${formattedSpeed(speed * 3.28084)} ft/s`;
    case 'beaufort':
      return `${windSpeedToBeaufort(speed)} bf`;
    default:
      return `${formattedSpeed(speed)} m/s`; // Podrazumevano je m/s
  }
};

// Konverzija brzine vetra u Beaufort skalu
const windSpeedToBeaufort = (speed) => {
  const speeds = [0.3, 1.6, 3.4, 5.5, 8.0, 10.8, 13.9, 17.2, 20.8, 24.5, 28.5, 32.7];
  for (let i = 0; i < speeds.length; i++) {
    if (speed < speeds[i]) {
      return i;
    }
  }
  return 12; // Hurricane
};

// Konverzija temperature na osnovu zadate jedinice
export const convertTemperature = (temperature, unitString) => {
  switch (unitString) {
    case 'C':
      return `${temperature.toFixed(0)}°C`; // Celzijus
    case 'F':
      return `${((temperature * 9) / 5 + 32).toFixed(0)}°F`; // Farenhajt
    case 'K':
      return `${(temperature + 273.15).toFixed(0)}°K`; // Kelvin
    default:
      throw new Error("Unknown temperature unit");
  }
};

export const WindDirection = {
  O: 'o',
  N: 'N',
  NNE: 'NNE',
  NE: 'NE',
  ENE: 'ENE',
  E: 'E',
  ESE: 'ESE',
  SE: 'SE',
  SSE: 'SSE',
  S: 'S',
  SSW: 'SSW',
  SW: 'SW',
  WSW: 'WSW',
  W: 'W',
  WNW: 'WNW',
  NW: 'NW',
  NNW: 'NNW',
};

export const getLongName = (direction) => {
  const longNames = {
    [WindDirection.O]: '',
    [WindDirection.N]: 'N',
    [WindDirection.NNE]: 'NNE',
    [WindDirection.NE]: 'NE',
    [WindDirection.ENE]: 'ENE',
    [WindDirection.E]: 'E',
    [WindDirection.ESE]: 'ESE',
    [WindDirection.SE]: 'SE',
    [WindDirection.SSE]: 'SSE',
    [WindDirection.S]: 'S',
    [WindDirection.SSW]: 'SSW',
    [WindDirection.SW]: 'SW',
    [WindDirection.WSW]: 'WSW',
    [WindDirection.W]: 'W',
    [WindDirection.WNW]: 'WNW',
    [WindDirection.NW]: 'NW',
    [WindDirection.NNW]: 'NNW',
  };
  return longNames[direction] || 'Unknown';
};

export const getDirectionFromAngle = (windAngle) => {
  const directions = [
    { direction: WindDirection.O, range: [0.0, 0.1] },
    { direction: WindDirection.N, range: [349.5, 360.0] },
    { direction: WindDirection.N, range: [0.0, 11.5] },
    { direction: WindDirection.NNE, range: [11.5, 33.5] },
    { direction: WindDirection.NE, range: [33.5, 56.5] },
    { direction: WindDirection.ENE, range: [56.5, 78.5] },
    { direction: WindDirection.E, range: [78.5, 101.5] },
    { direction: WindDirection.ESE, range: [101.5, 123.5] },
    { direction: WindDirection.SE, range: [123.5, 146.5] },
    { direction: WindDirection.SSE, range: [146.5, 168.5] },
    { direction: WindDirection.S, range: [168.5, 191.5] },
    { direction: WindDirection.SSW, range: [191.5, 213.5] },
    { direction: WindDirection.SW, range: [213.5, 236.5] },
    { direction: WindDirection.WSW, range: [236.5, 258.5] },
    { direction: WindDirection.W, range: [258.5, 281.5] },
    { direction: WindDirection.WNW, range: [281.5, 303.5] },
    { direction: WindDirection.NW, range: [303.5, 326.5] },
    { direction: WindDirection.NNW, range: [326.5, 349.5] },
  ];

  const directionObj = directions.find(
    ({ range }) => windAngle >= range[0] && windAngle <= range[1]
  );
  return directionObj ? directionObj.direction : WindDirection.N;
};

export const getDirectionFromString = (windAngle) => {
  const angle = parseFloat(windAngle);
  return isNaN(angle) ? WindDirection.O : getDirectionFromAngle(angle);
};

export const WeatherDescription = {
  languages: ['en', 'el', 'hr', 'rs', 'al', 'mk', 'de', 'it', 'es'],
  dayDescriptions: {
    d01: {
      en: 'Sunny',
      el: 'Ηλιόλουστος',
      hr: 'Sunčano',
      rs: 'Sunčano',
      al: 'Me diell',
      mk: 'Сончево',
      de: 'Sonnig',
      it: 'Soleggiato',
      es: 'Soleado'
    },
    d02: {
      en: 'Hot day',
      el: 'Ζεστή ημέρα',
      hr: 'Vruć dan',
      rs: 'Vreo dan',
      al: 'Ditë e nxehtë',
      mk: 'Жежок ден',
      de: 'Heißer Tag',
      it: 'Giornata calda',
      es: 'Día caluroso'
    },
    d03: {
      en: 'Partly cloudy',
      el: 'Μερικώς νεφελώδης',
      hr: 'Djelomično oblačno',
      rs: 'Delimično oblačno',
      al: 'Pjesërisht me re',
      mk: 'Делумно облачно',
      de: 'Teilweise bewölkt',
      it: 'Parzialmente nuvoloso',
      es: 'Parcialmente nublado'
    },
    d04: {
      en: 'Mostly cloudy',
      el: 'Κυρίως νεφελώδης',
      hr: 'Uglavnom oblačno',
      rs: 'Uglavnom oblačno',
      al: 'Kryesisht me re',
      mk: 'Претежно облачно',
      de: 'Überwiegend bewölkt',
      it: 'Per lo più nuvoloso',
      es: 'Mayormente nublado'
    },
    d05: {
      en: 'Showers',
      el: 'Βροχοπτώσεις',
      hr: 'Pljuskovi',
      rs: 'Pljuskovi',
      al: 'Shira',
      mk: 'Пороен дожд',
      de: 'Schauer',
      it: 'Rovesci',
      es: 'Chubascos'
    },
    d06: {
      en: 'Cloudy, sleet',
      el: 'Συννεφιά, χιονόνερο',
      hr: 'Oblačno, susnježica',
      rs: 'Oblačno, susnežica',
      al: 'Me re, me breshër',
      mk: 'Облачно, со лапавица',
      de: 'Bewölkt, Schneeregen',
      it: 'Nuvoloso, nevischio',
      es: 'Nublado, aguanieve'
    },
    d07: {
      en: 'Snow shower',
      el: 'Χιονόπτωση',
      hr: 'Snježni pljusak',
      rs: 'Snežni pljusak',
      al: 'Breshër bore',
      mk: 'Снегови врнежи',
      de: 'Schneeschauer',
      it: 'Rovescio di neve',
      es: 'Chubasco de nieve'
    },
    d08: {
      en: 'Overcast',
      el: 'Συννεφιασμένος',
      hr: 'Oblačno',
      rs: 'Oblačno',
      al: 'E vrenjtur',
      mk: 'Облачно',
      de: 'Bedeckt',
      it: 'Coperto',
      es: 'Cubierto'
    },
    d09: {
      en: 'Light rain',
      el: 'Ελαφριά βροχή',
      hr: 'Slaba kiša',
      rs: 'Slaba kiša',
      al: 'Shi i lehtë',
      mk: 'Лесен дожд',
      de: 'Leichter Regen',
      it: 'Pioggia leggera',
      es: 'Lluvia ligera'
    },
    d10: {
      en: 'Heavy rain',
      el: 'Καταρρακτώδης βροχή',
      hr: 'Jaka kiša',
      rs: 'Jaka kiša',
      al: 'Shi i rëndë',
      mk: 'Голем дожд',
      de: 'Starker Regen',
      it: 'Pioggia forte',
      es: 'Lluvia intensa'
    },
    d11: {
      en: 'Snow',
      el: 'Χιόνι',
      hr: 'Snijeg',
      rs: 'Sneg',
      al: 'Borë',
      mk: 'Снег',
      de: 'Schnee',
      it: 'Neve',
      es: 'Nieve'
    },
    d12: {
      en: 'Sleet',
      el: 'Χιονόνερο',
      hr: 'Susnježica',
      rs: 'Susnežica',
      al: 'Breshër',
      mk: 'Лапавица',
      de: 'Schneeregen',
      it: 'Nevischio',
      es: 'Aguanieve'
    },
    d13: {
      en: 'Thundershowers',
      el: 'Καταιγίδες',
      hr: 'Grmljavinski pljuskovi',
      rs: 'Grmljavinski pljuskovi',
      al: 'Rrebeshe me bubullima',
      mk: 'Грмежни врнежи',
      de: 'Gewitterschauer',
      it: 'Temporali',
      es: 'Tormentas'
    },
    d14: {
      en: 'Fog',
      el: 'Ομίχλη',
      hr: 'Magla',
      rs: 'Magla',
      al: 'Mjegull',
      mk: 'Магла',
      de: 'Nebel',
      it: 'Nebbia',
      es: 'Niebla'
    },
    d15: {
      en: 'Fog',
      el: 'Ομίχλη',
      hr: 'Magla',
      rs: 'Magla',
      al: 'Mjegull',
      mk: 'Магла',
      de: 'Nebel',
      it: 'Nebbia',
      es: 'Niebla'
    },
  },
  nightDescriptions: {
    n01: {
      en: 'Clear',
      el: 'Καθαρός ουρανός',
      hr: 'Vedro',
      rs: 'Vedro',
      al: 'E kthjellët',
      mk: 'Ведро',
      de: 'Klar',
      it: 'Sereno',
      es: 'Despejado'
    },
    n02: {
      en: 'Clear and very hot',
      el: 'Καθαρός και πολύ ζεστός',
      hr: 'Vedro i vrlo toplo',
      rs: 'Vedro i vrlo toplo',
      al: 'E kthjellët dhe shumë nxehtë',
      mk: 'Ведро и многу топло',
      de: 'Klar und sehr heiß',
      it: 'Sereno e molto caldo',
      es: 'Despejado y muy caluroso'
    },
    n03: {
      en: 'Partly cloudy',
      el: 'Μερικώς νεφελώδης',
      hr: 'Djelomično oblačno',
      rs: 'Delimično oblačno',
      al: 'Pjesërisht me re',
      mk: 'Делумно облачно',
      de: 'Teilweise bewölkt',
      it: 'Parzialmente nuvoloso',
      es: 'Parcialmente nublado'
    },
    n04: {
      en: 'Mostly cloudy',
      el: 'Κυρίως νεφελώδης',
      hr: 'Uglavnom oblačno',
      rs: 'Uglavnom oblačno',
      al: 'Kryesisht me re',
      mk: 'Претежно облачно',
      de: 'Überwiegend bewölkt',
      it: 'Per lo più nuvoloso',
      es: 'Mayormente nublado'
    },
    n05: {
      en: 'Showers',
      el: 'Βροχοπτώσεις',
      hr: 'Pljuskovi',
      rs: 'Pljuskovi',
      al: 'Shira',
      mk: 'Пороен дожд',
      de: 'Schauer',
      it: 'Rovesci',
      es: 'Chubascos'
    },
    n06: {
      en: 'Cloudy, sleet',
      el: 'Συννεφιά, χιονόνερο',
      hr: 'Oblačno, susnježica',
      rs: 'Oblačno, susnežica',
      al: 'Me re, me breshër',
      mk: 'Облачно, со лапавица',
      de: 'Bewölkt, Schneeregen',
      it: 'Nuvoloso, nevischio',
      es: 'Nublado, aguanieve'
    },
    n07: {
      en: 'Snow shower',
      el: 'Χιονόπτωση',
      hr: 'Snježni pljusak',
      rs: 'Snežni pljusak',
      al: 'Breshër bore',
      mk: 'Снегови врнежи',
      de: 'Schneeschauer',
      it: 'Rovescio di neve',
      es: 'Chubasco de nieve'
    },
    n08: {
      en: 'Overcast',
      el: 'Συννεφιασμένος',
      hr: 'Oblačno',
      rs: 'Oblačno',
      al: 'E vrenjtur',
      mk: 'Облачно',
      de: 'Bedeckt',
      it: 'Coperto',
      es: 'Cubierto'
    },
    n09: {
      en: 'Light rain',
      el: 'Ελαφριά βροχή',
      hr: 'Slaba kiša',
      rs: 'Slaba kiša',
      al: 'Shi i lehtë',
      mk: 'Лесен дожд',
      de: 'Leichter Regen',
      it: 'Pioggia leggera',
      es: 'Lluvia ligera'
    },
    n10: {
      en: 'Heavy rain',
      el: 'Καταρρακτώδης βροχή',
      hr: 'Jaka kiša',
      rs: 'Jaka kiša',
      al: 'Shi i rëndë',
      mk: 'Голем дожд',
      de: 'Starker Regen',
      it: 'Pioggia forte',
      es: 'Lluvia intensa'
    },
    n11: {
      en: 'Snow',
      el: 'Χιόνι',
      hr: 'Snijeg',
      rs: 'Sneg',
      al: 'Borë',
      mk: 'Снег',
      de: 'Schnee',
      it: 'Neve',
      es: 'Nieve'
    },
    n12: {
      en: 'Sleet',
      el: 'Χιονόνερο',
      hr: 'Susnježica',
      rs: 'Susnežica',
      al: 'Breshër',
      mk: 'Лапавица',
      de: 'Schneeregen',
      it: 'Nevischio',
      es: 'Aguanieve'
    },
    n13: {
      en: 'Thundershowers',
      el: 'Καταιγίδες',
      hr: 'Grmljavinski pljuskovi',
      rs: 'Grmljavinski pljuskovi',
      al: 'Rrebeshe me bubullima',
      mk: 'Грмежни врнежи',
      de: 'Gewitterschauer',
      it: 'Temporali',
      es: 'Tormentas'
    },
    n14: {
      en: 'Fog',
      el: 'Ομίχλη',
      hr: 'Magla',
      rs: 'Magla',
      al: 'Mjegull',
      mk: 'Магла',
      de: 'Nebel',
      it: 'Nebbia',
      es: 'Niebla'
    },
    n15: {
      en: 'Fog',
      el: 'Ομίχλη',
      hr: 'Magla',
      rs: 'Magla',
      al: 'Mjegull',
      mk: 'Магла',
      de: 'Nebel',
      it: 'Nebbia',
      es: 'Niebla'
    },
  },
  getWeatherDescription: (icon, lang = 'en') => {
    // Remove the reference to 'userLang' here
    // Use 'lang' parameter directly
    if (!WeatherDescription.languages.includes(lang)) {
      lang = 'en';
    }

    let descriptions = icon.startsWith('d')
      ? WeatherDescription.dayDescriptions
      : WeatherDescription.nightDescriptions;

    if (descriptions && descriptions[icon] && descriptions[icon][lang]) {
      return descriptions[icon][lang];
    } else if (descriptions && descriptions[icon] && descriptions[icon]['en']) {
      return descriptions[icon]['en'];
    } else {
      return 'Unknown';
    }
  },
};


export const WindDescription = {
  languages: ['en', 'el', 'hr', 'rs', 'al', 'mk', 'de', 'it', 'es'],
  descriptions: {
    Calm: {
      en: 'calm',
      el: 'γαλήνη',
      hr: 'tiho i bez vjetra',
      rs: 'tiho i bez vetra',
      al: 'i qetë',
      mk: 'мирно',
      de: 'ruhig',
      it: 'calmo',
      es: 'calma'
    },
    LightAirs: {
      en: 'light airs',
      el: 'απαλά ρεύματα',
      hr: 'laki povjetarac',
      rs: 'lak povetarac',
      al: 'erë e lehtë',
      mk: 'лесен ветер',
      de: 'leichte Luftzüge',
      it: 'brezze leggere',
      es: 'brisas ligeras'
    },
    LightBreeze: {
      en: 'light breeze',
      el: 'απαλή αύρα',
      hr: 'slabi povjetarac',
      rs: 'slab povetarac',
      al: 'fllad i lehtë',
      mk: 'лесен повев',
      de: 'leichte Brise',
      it: 'brezza leggera',
      es: 'brisa ligera'
    },
    GentleBreeze: {
      en: 'gentle breeze',
      el: 'απαλός άνεμος',
      hr: 'umjeren povjetarac',
      rs: 'umeren povetarac',
      al: 'fllad i butë',
      mk: 'нежен повев',
      de: 'sanfte Brise',
      it: 'brezza gentile',
      es: 'brisa suave'
    },
    ModerateBreeze: {
      en: 'moderate breeze',
      el: 'μέτριος άνεμος',
      hr: 'umjeren vjetar',
      rs: 'umeren vetar',
      al: 'fllad mesatar',
      mk: 'умерен ветер',
      de: 'mäßige Brise',
      it: 'brezza moderata',
      es: 'brisa moderada'
    },
    FreshBreeze: {
      en: 'fresh breeze',
      el: 'δροσερός άνεμος',
      hr: 'svjež vjetar',
      rs: 'svež vetar',
      al: 'fllad i freskët',
      mk: 'свеж повев',
      de: 'frische Brise',
      it: 'brezza fresca',
      es: 'brisa fresca'
    },
    StrongBreeze: {
      en: 'strong breeze',
      el: 'ισχυρός άνεμος',
      hr: 'jak vjetar',
      rs: 'jak vetar',
      al: 'fllad i fortë',
      mk: 'силен ветер',
      de: 'starke Brise',
      it: 'brezza forte',
      es: 'brisa fuerte'
    },
    Moderate: {
      en: 'moderate',
      el: 'μέτριος',
      hr: 'umjereno do jak vjetar',
      rs: 'umereno do jak vetar',
      al: 'mesatar',
      mk: 'умерен',
      de: 'mäßig',
      it: 'moderato',
      es: 'moderado'
    },
    FreshGale: {
      en: 'fresh gale',
      el: 'δυνατός άνεμος',
      hr: 'jak vjetar',
      rs: 'jak vetar',
      al: 'stuhi e freskët',
      mk: 'свежа бура',
      de: 'frische Böe',
      it: 'raffica fresca',
      es: 'ráfaga fresca'
    },
    SevereGale: {
      en: 'severe gale',
      el: 'σφοδρός άνεμος',
      hr: 'olujni vjetar',
      rs: 'olujni vetar',
      al: 'stuhi e fortë',
      mk: 'силна бура',
      de: 'starker Sturm',
      it: 'forte tempesta',
      es: 'vendaval fuerte'
    },
    Storm: {
      en: 'storm',
      el: 'καταιγίδα',
      hr: 'oluja',
      rs: 'oluja',
      al: 'stuhi',
      mk: 'невреме',
      de: 'Sturm',
      it: 'tempesta',
      es: 'tormenta'
    },
    ViolentStorm: {
      en: 'violent storm',
      el: 'σφοδρή καταιγίδα',
      hr: 'silovita oluja',
      rs: 'silovita oluja',
      al: 'stuhi e dhunshme',
      mk: 'жестоко невреме',
      de: 'heftiger Sturm',
      it: 'tempesta violenta',
      es: 'tormenta violenta'
    },
    Hurricane: {
      en: 'hurricane',
      el: 'τυφώνας',
      hr: 'uragan',
      rs: 'uragan',
      al: 'uragan',
      mk: 'ураган',
      de: 'Hurrikan',
      it: 'uragano',
      es: 'huracán'
    },
  },
};

export const getWindDescription = (windSpeed, lang = 'en') => {
  const windSpeedInKMH = windSpeed * 3.6;
  let key = '';

  if (windSpeedInKMH < 2) key = 'Calm';
  else if (windSpeedInKMH < 7) key = 'LightAirs';
  else if (windSpeedInKMH < 12) key = 'LightBreeze';
  else if (windSpeedInKMH < 20) key = 'GentleBreeze';
  else if (windSpeedInKMH < 31) key = 'ModerateBreeze';
  else if (windSpeedInKMH < 40) key = 'FreshBreeze';
  else if (windSpeedInKMH < 51) key = 'StrongBreeze';
  else if (windSpeedInKMH < 62) key = 'Moderate';
  else if (windSpeedInKMH < 75) key = 'FreshGale';
  else if (windSpeedInKMH < 89) key = 'SevereGale';
  else if (windSpeedInKMH < 103) key = 'Storm';
  else if (windSpeedInKMH < 118) key = 'ViolentStorm';
  else key = 'Hurricane';

  // Provera da li je jezik podržan, podrazumevano engleski
  if (!WindDescription.languages.includes(lang)) {
    lang = 'en';
  }

  const descriptionObj = WindDescription.descriptions[key];
  if (descriptionObj && descriptionObj[lang]) {
    return descriptionObj[lang];
  } else if (descriptionObj && descriptionObj['en']) {
    return descriptionObj['en'];
  } else {
    return 'Unknown';
  }
};


